<template>
  <div>
    <AppBanner :url="require('@/assets/image/cover/products.png')" title="產品" />
    <AppBreadcrumb :items="breadcrumb" :border="false" />

    <div class="container py-6">
      <div class="px-lg-8">
        <h2 v-if="productType" class="content-title fs-1 text-center mb-5">
          {{ productType.nameCh }}
        </h2>
        <ul class="row g-lg-3 py-lg-5 align-items-stretch" v-if="brands.length">
          <li class="col-6 col-md-4 col-lg-3 mb-3" v-for="brand in brands" :key="brand.nameEn">
            <BrandProductCard
              :brand="brand.nameEn"
              :image="brand.imagePath"
              :contents="brand.productInfos"
              :redirectUrl="`/product/${brand.nameEn}?anchor=${brand.productInfos[0].nameEn}`"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { apiQueryUserBrandBoard } from "@/api/brand";
import { apiQueryProduct } from "@/api/product";
import BrandProductCard from "../../components/BrandProductCard.vue";

export default {
  name: "products",
  components: {
    BrandProductCard,
  },
  data() {
    return {
      brands: [],
      productType: null,
    };
  },
  computed: {
    $routeQueryType() {
      const { type } = this.$route.query;
      return type;
    },
    breadcrumb() {
      return [
        {
          title: "產品",
          path: "/product",
        },
        {
          title: this.$routeQueryType ? this.productType?.nameCh : "全部",
          path: this.$routeQueryType ? `/product?type=${this.$routeQueryType}` : "/product",
        },
      ];
    },
  },
  methods: {
    async queryBrandBoard({ nameEn } = {}) {
      this.$vLoading(true);
      try {
        const { data } = await apiQueryUserBrandBoard({ product: nameEn });
        this.brands = data.data.sort((a, b) =>
          a.nameEn.toLowerCase() > b.nameEn.toLowerCase() ? 1 : -1,
        );
      } catch (e) {
        //regardless
      } finally {
        this.$vLoading(false);
      }
    },
    async queryProduct() {
      try {
        this.$vLoading(true);
        const { data } = await apiQueryProduct();
        this.products = data.data;
      } catch (e) {
        //regardless
      } finally {
        this.$vLoading(false);
      }
    },
    beforeQueryBrandBoard(type) {
      this.productType =
        type && this.products.find((p) => p.nameEn.toLowerCase() === type.toLowerCase());
      if (this.productType) {
        this.queryBrandBoard({ nameEn: type });
      } else {
        this.queryBrandBoard();
      }
    },
  },
  watch: {
    $routeQueryType(val) {
      this.beforeQueryBrandBoard(val);
    },
  },
  async created() {
    await this.queryProduct();
    this.beforeQueryBrandBoard(this.$routeQueryType);
  },
};
</script>
